import { SET_BLOCKCHAIN_DATA } from '../actions/types';

const initialState = {
  productList: [],
  apiData: {}
};
function productReducers(state = initialState, action) {
  switch (action.type) {
    case SET_BLOCKCHAIN_DATA:
      return { ...state, apiData: action.payload };
    default:
      return state;
  }
}

export default productReducers;
