import React from 'react';
import loading from '../images/loader.png';

const Loader = () => (
  <div>
    <img
      src={loading}
      data-testid="loading"
      alt="loading"
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '299px',
        width: '70px'
      }}
    />
  </div>
);

export default Loader;
