import React from 'react';
import Routes from './routes';
import './styles/App.css';
import { Provider } from 'react-redux';
import { withLocalData } from '@mafc/common';
import store from './reducers';

import { ThemeProvider } from '@mafc/trolly';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-WFM7596'
};

TagManager.initialize(tagManagerArgs);

withLocalData();
function App() {
  return (
    <div className="App" data-testid="appId">
      <Provider store={store}>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
