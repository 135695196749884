import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loader from './components/loader';

const BatchCodeComponent = lazy(() => import('./components/batchcode'));
const ProductDetails = lazy(() => import('./components/product-details'));
const PdfViewer = lazy(() => import('./components/product-details/pdfviewer2'));
const QRCodeScanner = lazy(() => import('./components/product-details/qrcode'));
const ErrorPage = lazy(() => import('./components/errorscreen'));
const QRCodeGenerator = lazy(() => import('./components/qrcode-generator'));

const Routes = () => (
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route
          exact
          path={['/', '/:lang/:productType/pid/:pid/storecode/:storecode']}
          component={BatchCodeComponent}
        />

        <Route
          exact
          path={[
            '/product-details/:lang/:productType/:productcode/:storecode/:dateOrLotNumber',
            '/product/:lang/:productType/:productcode/:storecode/:dateOrLotNumber'
          ]}
          component={ProductDetails}
        />
        <Route exact path="/show-pdf" component={PdfViewer} />
        <Route exact path="/error-page" component={ErrorPage} />
        <Route exact path="/camera" component={QRCodeScanner} />
        <Route exact path="/qrcode-generator" component={QRCodeGenerator} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default Routes;
